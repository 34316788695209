import React from "react"
import PropTypes from "prop-types"
import SEO from "components/molecules/SEO"
import Layout from "components/molecules/Layout"
import Legals from "components/molecules/Legals"

const TERMS_TOPICS = [
  "termsAndConditions",
  "disclaimer",
  "secDisclosure",
  "trademarksAndCopyrights",
  "linksTo",
  "linksFrom",
  "transmissions",
  "jurisdictionAndGoverningLaw",
  "limitation",
  "waiver",
  "integrationAndSeverability",
]

const TermsPage = ({ pageContext: { language } }) => (
  <Layout language={language}>
    <SEO title="Terms and Conditions" />
    <Legals topics={TERMS_TOPICS} pageType="terms" />
  </Layout>
)

TermsPage.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
}

export default TermsPage
